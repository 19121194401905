<template>
  <section>
    <v-row>
      <v-breadcrumbs large :items="breadcrumbs" style="color:#404a7a"></v-breadcrumbs>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <p style="font-size: 24px; :color='secondary' ">
          {{ itemsObs.codigo }} {{ itemsObs.nombre }}
        </p>
      </v-col>
      <v-col md="3" offset-md="9">
        <v-text-field
          label="Especificación"
          @keyup="getPeticion"
          v-model="especificacion"
          outlined
          placeholder="Buscar..."
        >
        </v-text-field>
      </v-col>
    </v-row>
    <DataTable
      :headers="headers"
      :items="items"
      v-models:select="por_pagina"
      v-models:pagina="pagina"
      :total_registros="total_registros"
      @paginar="paginar"
      @sendItem="sendItem"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn outlined color="#404a7a" @click="sendItem(item.id)">Ver ofertas</v-btn>
      </template>
    </DataTable>
    <v-row>
      <v-col>
        <v-btn outlined @click="volver" color="#404a7a"> Volver </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "ListarOfertasPaacUaci",
  components: {
    DataTable,
  },
  data: () => ({
    //Para el textfield
    especificacion: "",
    //Paginación
    pagina: 1,
    por_pagina: 5,
    total_registros: null,
    itemsObs: [],
    id_sub_proceso: null,
    headers: [
      {
        text: "Especifiación",
        align: "start",
        width: "75%",
        sortable: true,
        value: "nombre_agrupacion",
      },
      {
        text: "Cantidad",
        align: "start",
        sortable: true,
        value: "cantidad",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: true,
        value: "actions",
      },
    ],
    items: [],
    selectObs: {},

  }),

  methods: {
    ...mapActions("procesoCompra", [
      "getInfoGeneralProcesoCompra",
    ]),
    async getOfertas() {
      let params = {
        page: this.pagina,
        per_page: this.por_pagina,
        pagination: true,
        especificacion: this.especificacion,
      };
      let idSubproceso = this.$route.params.id_proceso_obs;
      let response = await this.services.Paac.getOfertasDetalle(
        idSubproceso,
        params
      );
      this.items = response.data.dataSubProcesos;
      this.itemsObs = response.data.dataObs;
      this.total_registros = Number(response.headers.total_rows);
    },

    getPeticion() {
      setTimeout(() => {
        this.getOfertas();
      }, 1000);
    },

    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.pagina = pagina;
      this.por_pagina = cantidad_por_pagina;
      this.getOfertas();
    },

    volver() {
      this.$router.go(-1);
    },

    sendItem(id_sub_proceso) {
      this.id_sub_proceso = id_sub_proceso;
       this.$router.push({
        name: "aceptacion-evaluacion-proceso-uaci",
        params: { id_proceso: this.$route.params.id_proceso, id_proceso_obs: this.$route.params.id_proceso_obs, id_sub_proceso: this.id_sub_proceso },
      });
    },
    async getInfoGeneral() {
      const response = await this.getInfoGeneralProcesoCompra(this.$route.params.id_proceso);
      this.selectObs = response.data
    },
  },

  async created() {
    await this.getOfertas();
    await this.getInfoGeneral()
  },

  computed:{
    ...mapState("procesoCompra",[
      "info_general",
    ]),
    breadcrumbs(){
      return[
          {
            text: "Procesos",
            disabled: false,
            href: "#",
            color: 'green',
          },
          {
            text: this.selectObs.codigo_proceso,
            disabled: false,
            href: "#",
          },
          {
            text: 'Ofertas'  ,
            disabled: false,
            href: "#",
          },
          {
            text: this.itemsObs.codigo +" - "+this.itemsObs.nombre ,
            disabled: false,
           
          },
      ]
    } 
  }
};
</script>
